// React Imports
import React, { useEffect, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import CountUp from 'react-countup';
import { date } from 'yup';

// Import thunk for API fetching
import { getSentimentData } from 'slices/sentiment/thunk';
import { getWordcloudDatas } from 'slices/wordcloud/thunk';
import { getMetricDatas } from 'slices/metrics/thunk';
import { fetchChannelSentimentIndividual } from 'slices/channel_sentiment_individual/thunk';

// Import Components 
import BreadCrumb from 'Common/BreadCrumb';
import WelcomeWidget from './ChannelWelcomeWidget';
import LineOverview from './ChannelLineOverview';
import { Smile } from 'lucide-react';
import { AverageUserPosts, StackedColumns, StackedColumns100, WeeklyStackedColumn, WeeklyStackedColumn100 } from './ChannelColumnCharts';
import { PositivePost, NegativePost, FearGreed } from './FearGreedGauge';
import FearGreedGauge from './FearGreedIndex';
import { PositiveChart, NegativeChart, NeutralChart, TotalDailyPostChart, TotalDailyUserChart, ModsTotalPostChart } from './ChannelAreaChart';
import DatePicker from './DatePicker';

// Import Images
import DummyWordCloud from "assets/images/dummy-word-cloud.png";
import { SparklineChart } from './PostsGraphs';
import HourlySentiment from './HourlySentiment';

const ChannelDashboard = () => {
  const { channelId } = useParams<{ channelId: string }>();
  const dispatch = useAppDispatch();
  const channelSentimentIndividual = useAppSelector((state) => state.ChannelSentimentIndividual)

  // const { Channel_Name } = useAppSelector((state) => state.Sentiment)

  // console.log("Channel ID:", channelId);
  // console.log("Channel Name:", channelName);

  const { channelName, sentimentData, fearGreedGauge, loading, error, totalPostsChannelToday, totalNegativeChannelToday, totalPositiveChannelToday, differenceTotalPostsFromYesterday, differenceNegativePostsFromYesterday, differencePositivePostsFromYesterday, } = useAppSelector((state) => state.Sentiment);
  const { data: wordcloudImageUrl, loading: wordcloudLoading, error: wordcloudError } = useAppSelector((state) => state.ChannelWordcloud);
  const { metricData, loading: metricLoading, error: metricError } = useAppSelector((state) => state.ChannelMetric);

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [historicalSentimentMode, setHistoricalSentimentMode] = useState<'post' | 'daily' | 'dailyperc'>('post');

  // Function to fetch the sentiment data based on the channel_id
  useEffect(() => {
    if (channelId) {
      dispatch(getSentimentData(channelId as string));
      dispatch(getWordcloudDatas(channelId as string));
      dispatch(getMetricDatas(channelId as string));
      dispatch(fetchChannelSentimentIndividual(channelId as string));
    }
  }, [channelId, dispatch]);

  // Calculate totals using useMemo to avoid unnecessary recalculations
  const totals = useMemo(() => {
    if (!sentimentData || sentimentData.length === 0) {
      return { negative: 0, neutral: 0, positive: 0 };
    }
    return sentimentData.reduce((acc, data) => {
      acc.negative += data.Total_Negative;
      acc.neutral += data.Total_Neutral;
      acc.positive += data.Total_Positive;
      return acc;
    }, { negative: 0, neutral: 0, positive: 0 });
  }, [sentimentData]);

  console.log("Totals: ", totals);

  const latestSentimentData = useMemo(() => {
    if (sentimentData.length === 0) return null;
    return sentimentData[sentimentData.length - 1];
  }, [sentimentData]);

  const filteredSentimentData = useMemo(() => {
    if (!dateRange[0] || !dateRange[1]) return sentimentData;

    return sentimentData.filter((data) => {
      const dataDate = new Date(data.Date);
      console.log("Data date Channel:", dataDate, dataDate >= dateRange[0]! && dataDate <= dateRange[1]!);
      console.log(dateRange[0], dateRange[1]);
      return dataDate >= dateRange[0]! && dataDate < dateRange[1]!;
    });
  }, [sentimentData, dateRange]);

  const calculateRatio = (data: any) => {
    const total = data.Total_Positive + data.Total_Neutral + data.Total_Negative;
    return ((data.Total_Positive / total) * 100) - ((data.Total_Negative / total) * 100);
  };

  const sentimentPercentages = useMemo(() => {
    if (sentimentData.length < 2) return { positive: 0, neutral: 0, negative: 0, ratio: 0, ratioDiff: 0 };

    const todayData = sentimentData[sentimentData.length - 1];
    const yesterdayData = sentimentData[sentimentData.length - 2];

    const total = todayData.Total_Positive + todayData.Total_Neutral + todayData.Total_Negative;

    const todayRatio = calculateRatio(todayData);
    const yesterdayRatio = calculateRatio(yesterdayData);

    return {
      positive: (todayData.Total_Positive / total) * 100,
      neutral: (todayData.Total_Neutral / total) * 100,
      negative: (todayData.Total_Negative / total) * 100,
      ratio: todayRatio,
      ratioDiff: todayRatio - yesterdayRatio
    };
  }, [sentimentData]);

  const formattedRatioDiff = sentimentPercentages.ratioDiff > 0
    ? `+${sentimentPercentages.ratioDiff.toFixed(2)}`
    : sentimentPercentages.ratioDiff.toFixed(2);

  const handleDateChange = (selectedDates: Date[]) => {
    setDateRange([selectedDates[0] || null, selectedDates[1] || null]);
  };

  const handleClearDate = () => {
    setDateRange([null, null]);
  };

  // console.log(metricData[0]); 
  // console.log(metricData[0]?.Fear_Greed_Gauge);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <React.Fragment>
      <div className='container-fluid group-data-[content=boxed]:max-w-boxed mx-auto'>
        {/* Don't forget to replace the Channel with the dynamic channel name */}
        <div className="grid grid-cols-12 gap-x-5">
          <div className="col-span-12 mt-8">
            <WelcomeWidget />

            {/* Overview Text */}
            <div className='flex-row my-4 mx-1'>
              <h2 className='text-white'>Yesterday's Key Stats</h2> {/* Change to Channel Name */}
            </div>

            {/* First Upper Structure */}
            <div className='flex flex-row space-x-4'>

              {/* Normalized Fear/Greed Gauge */}
              <div className='card w-1/2 bg-gradient-to-b from-blue-900 via-blue-950 to-black rounded-lg flex flex-col'>
                <div className='card-body'>
                  <h6 className='text-white text-lg'>Fomo-Fud Index</h6>
                </div>
                <div className="border-t border-gray-600 dark:border-gray-300"></div>
                <div className='card-body flex-grow flex items-center justify-center'>
                  <div className='flex self-center items-center justify-center p-6'>
                    <FearGreedGauge Fear_Greed_Index={fearGreedGauge as unknown as number} />
                  </div>
                </div>
              </div>

              {/* Total Posts, Positive, and Negative Posts Card */}
              <div className="card w-1/2 bg-[#0A0D10] rounded-lg flex flex-col">
                {/* Card Title */}
                <div className='card-body bg-[#0A0D10]'>
                  <h6 className='text-white text-lg'>Total Posts Today</h6>
                </div>
                <div className="border-t border-gray-600 dark:border-gray-300"></div>

                {/* Body Section */}
                <div className='card-body bg-[#0A0D10] flex-grow flex flex-col justify-between'>
                  <div className='flex flex-col h-1/2'>
                    {/* Total Post Today Number and Graph */}
                    <div className='flex flex-row mb-4 justify-between items-center'>
                      {/* Total Post Today */}
                      <div className='flex flex-col mt-4'>
                        <h4 className="mb-2 fs-4xl text-6xl lg:text-8xl"><CountUp className="counter-value" end={totalPostsChannelToday} /></h4>
                        <p className={`text-base lg:text-lg ${differenceTotalPostsFromYesterday >= 0 ? 'text-green-500' : 'text-red-500'} mb-2`}>
                          {differenceTotalPostsFromYesterday >= 0 ? '↑' : '↓'} {Math.abs(differenceTotalPostsFromYesterday).toFixed(2)}%
                        </p>
                      </div>
                      {/* Graph for Total Post Today */}
                      <div className="w-1/2 lg:w-2/3 h-20 flex items-center">
                        <SparklineChart dataKey="total" />
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-row h-1/2 justify-between'>
                    {/* Positive Posts Card */}
                    <div className='card-body bg-gradient-to-b from-green-700 to-black rounded-lg h-full w-full mr-2'>
                      <div className="flex flex-row justify-between mt-6">
                        <div className="flex flex-col justify-between">
                          <h4 className="mb-2 fs-4xl text-6xl"><CountUp className="counter-value" end={totalPositiveChannelToday} decimals={0} /></h4>
                          <p className="mb-0 text-slate-500 dark:text-[#92AFD3] text-xl items-end">Positive Posts</p>
                        </div>
                        <div className="flex flex-col justify-between h-full">
                          <div className="flex-grow mb-2">
                            <SparklineChart dataKey="positive" />
                          </div>
                          <p className={`text-sm ${totalPositiveChannelToday >= 0 ? 'text-green-500' : 'text-red-500'} mb-2 text-end`}>
                            {totalPositiveChannelToday >= 0 ? '↑' : '↓'} {Math.abs(totalPositiveChannelToday).toFixed(2)}%
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* Negative Posts Card */}
                    <div className='card-body bg-gradient-to-b from-pink-700 to-black rounded-lg h-full w-full ml-2'>
                      <div className="flex flex-row justify-between mt-6">
                        <div className="flex flex-col justify-between">
                          <h4 className="mb-2 fs-4xl text-6xl"><CountUp className="counter-value" end={totalNegativeChannelToday} decimals={0} /></h4>
                          <p className="mb-0 text-slate-500 dark:text-[#92AFD3] text-xl items-end">Negative Posts</p>
                        </div>
                        <div className="flex flex-col justify-between h-full">
                          <div className="flex-grow mb-2">
                            <SparklineChart dataKey="negative" />
                          </div>
                          <p className={`text-sm ${totalNegativeChannelToday >= 0 ? 'text-green-500' : 'text-red-500'} mb-2 text-end`}>
                            {totalNegativeChannelToday >= 0 ? '↑' : '↓'} {Math.abs(totalNegativeChannelToday).toFixed(2)}%
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className='flex flex-row justify-between my-4 mx-1'>
                <h2 className='text-white'>Historical Sentiment</h2>
                <div className="relative z-10">
                  <DatePicker onDateChange={handleDateChange} onClear={handleClearDate} />
                </div>
              </div>
              <div className="-mt-8 mb-4 space-x-3">
                <button type="button" className="text-green-500 bg-green-100 btn hover:text-white hover:bg-green-600 focus:text-white focus:bg-green-600 focus:ring focus:ring-green-100 active:text-white active:bg-green-600 active:ring active:ring-green-100 dark:bg-green-500/20 dark:text-green-400 dark:hover:bg-green-500 dark:hover:text-white dark:focus:bg-green-500 dark:focus:text-white dark:active:bg-green-500 dark:active:text-white dark:ring-green-400/20" onClick={() => setHistoricalSentimentMode("post")}>Post Sentiment</button>
                <button type="button" className="text-orange-500 bg-orange-100 btn hover:text-white hover:bg-orange-600 focus:text-white focus:bg-orange-600 focus:ring focus:ring-orange-100 active:text-white active:bg-orange-600 active:ring active:ring-orange-100 dark:bg-orange-500/20 dark:text-orange-500 dark:hover:bg-orange-500 dark:hover:text-white dark:focus:bg-orange-500 dark:focus:text-white dark:active:bg-orange-500 dark:active:text-white dark:ring-orange-400/20" onClick={() => setHistoricalSentimentMode("daily")}>Daily Breakdown</button>
                <button type="button" className="text-sky-500 btn bg-sky-100 hover:text-white hover:bg-sky-600 focus:text-white focus:bg-sky-600 focus:ring focus:ring-sky-100 active:text-white active:bg-sky-600 active:ring active:ring-sky-100 dark:bg-sky-500/20 dark:text-sky-400 dark:hover:bg-sky-500 dark:hover:text-white dark:focus:bg-sky-500 dark:focus:text-white dark:active:bg-sky-500 dark:active:text-white dark:ring-sky-400/20" onClick={() => setHistoricalSentimentMode("dailyperc")}>Daily Breakdown Percentage</button>
              </div>
            </div>
            {/* Second Upper Structure  */}
            <div className='flex flex-row'>

              {/* Line Chart */}
              <div className="card w-2/3 mr-4">
                <div className="card-body">

                  {/* The Title and Date Picker */}
                  <div className='flex flex-row justify-between mb-4'>
                    <h6 className="mb-4 text-15">{
                      historicalSentimentMode === "post" ? "Post Sentiment" : historicalSentimentMode === "daily" ? "Daily Breakdown" : historicalSentimentMode === "dailyperc" ? "Daily Breakdown Percentage" : ""
                    }</h6>
                  </div>
                  {
                    historicalSentimentMode === 'post' ? (
                      <PositiveChart chartId='positive-chart' data={filteredSentimentData} dateRange={dateRange} />
                    ) : historicalSentimentMode === 'daily' ? (
                      <StackedColumns chartId="stackedColumnChart" dateRange={dateRange} />
                    ) : historicalSentimentMode === 'dailyperc' ? (
                      <StackedColumns100 chartId="stackedColumnChart" dateRange={dateRange} />
                    ) : <></>
                  }
                  {/* <NeutralChart chartId='neutral-chart' data={filteredSentimentData} dateRange={dateRange}/>
                  <NegativeChart chartId='negative-chart' data={filteredSentimentData} dateRange={dateRange}/> */}

                  {/* Divider Line */}
                  {/* <div className="border-t border-gray-600 dark:border-gray-300 my-4"></div> */}

                  {/* Card Section */}
                  {/* <div className='flex flex-row'>
                    <div className="px-3 py-6 mx-3 w-1/3 text-center border rounded-md border-[#3BCDC2]">
                      <h4 className="mb-2 fs-4xl"><CountUp className="counter-value" end={totals.negative} decimals={0} /></h4>
                      <p className="mb-0 text-slate-500 dark:text-zink-200">Total Negative</p>
                    </div>
                    <div className="px-3 py-6 mx-3 w-1/3 text-center border rounded-md border-[#D579EF]">
                      <h4 className="mb-2 fs-4xl"><CountUp className="counter-value" end={totals.neutral} decimals={0} /></h4>
                      <p className="mb-0 text-slate-500 dark:text-zink-200">Total Neutral</p>
                    </div>
                    <div className="px-3 py-6 mx-3 w-1/3 text-center border rounded-md border-[#5894FF]">
                      <h4 className="mb-2 fs-4xl"><CountUp className="counter-value" end={totals.positive} decimals={0} /></h4>
                      <p className="mb-0 text-slate-500 dark:text-zink-200">Total Positive</p>
                    </div>
                  </div> */}

                </div>
              </div>

              <div className='card flex flex-col w-1/3'>

                {/* Daily Bar Chart */}
                <div className="card">
                  <div className="card-body">
                    <h6 className="mb-4 text-15">Total Posts Per Day</h6>
                    <TotalDailyPostChart chartId='total-daily-post' data={filteredSentimentData} dateRange={dateRange} />
                  </div>
                </div>

                {/* Daily Bar Percentage */}
                <div className="card">
                  <div className="card-body">
                    <h6 className="mb-4 text-15">Daily Active Users</h6>
                    <TotalDailyUserChart chartId='total-daily-user' data={filteredSentimentData} dateRange={dateRange} />
                  </div>
                </div>
                {/* <div className='card-body flex flex-col justify-between flex-grow'>
                  <div className="text-center border rounded-md border-[#3BCDC2] p-4 mb-4 flex-grow flex flex-col justify-center">
                    <h4 className="mb-2 fs-4xl text-6xl"><CountUp className="counter-value" end={latestSentimentData?.Total_Post as number} decimals={0} /></h4>
                    <p className="mb-0 text-slate-500 dark:text-zink-200 text-wrap text-xl">Total Daily Post</p>
                  </div>
                  <div className="text-center border rounded-md border-[#D579EF] p-4 flex-grow flex flex-col justify-center">
                    <h4 className="mb-2 fs-4xl text-6xl"><CountUp className="counter-value" end={latestSentimentData?.Total_Daily_User_Today as number} decimals={0} /></h4>
                    <p className="mb-0 text-slate-500 dark:text-zink-200 text-wrap text-xl">Total Daily User</p>
                  </div>
                </div> */}

              </div>

            </div>

            {/* Second Lower Structure */}
            <div className='flex flex-row'>

              {/* Average Posts Per User */}
              <div className="card w-1/2 pr-4 mr-4">
                <div className="card-body">
                  <h6 className="mb-4 text-2xl">Average Posts Per User</h6>
                </div>
                <div className="border-t border-gray-600 dark:border-gray-300 my-2"></div>
                <div className='card-body flex-col'>
                  <AverageUserPosts chartId='average-user-post' dateRange={dateRange} />
                </div>
              </div>

              {/* Negative Post Today */}
              <div className="card w-1/2 pr-4 mr-4">
                <div className="card-body">
                  <h6 className="mb-4 text-2xl">Mods Posts Per Day</h6>
                </div>
                <div className="border-t border-gray-600 dark:border-gray-300 my-2"></div>
                <div className='card-body flex-col'>
                  <ModsTotalPostChart chartId='mods-post-per-day' data={filteredSentimentData} dateRange={dateRange} />
                  {/* <p className='text-red-600 text-lg'>{metricData[0]?.Negative_Posts_Today || 'No negative posts today.'}</p> */}
                </div>
              </div>
            </div>

            {/* Hourly Sentiment Section */}
            <div className="card pr-4 mr-4">
              <div className="card-body">
                <h6 className="mb-4 text-2xl">Hourly Sentiment</h6>
              </div>
              <div className="border-t border-gray-600 dark:border-gray-300 my-2"></div>
              <div className='card-body flex-col relative'>
                <HourlySentiment data={channelSentimentIndividual.data} />
              </div>
            </div>

            {/* Lowest Structure */}
            <div className='flex flex-row'>

              {/* Positive Wordcloud */}
              <div className="card w-1/2 pr-4 mr-4">
                <div className='card-body'>
                  <h6 className='text-white text-lg'>Positive Trending Topics</h6>
                </div>
                <div className="border-t border-gray-600 dark:border-gray-300 my-4"></div>
                <div className='card-body flex-grow flex items-center justify-center'>
                  {wordcloudLoading ? (
                    <p>Loading positive wordcloud...</p>
                  ) : wordcloudError ? (
                    <p>Error loading positive wordcloud: {wordcloudError}</p>
                  ) : wordcloudImageUrl.wordcloud_positive ? (
                    <img src={wordcloudImageUrl.wordcloud_positive} alt="Positive Wordcloud" className="max-w-full max-h-full object-contain" />
                  ) : (
                    <p className="text-center text-slate-500">No positive wordcloud</p>
                  )}
                </div>
              </div>

              {/* Negative Wordcloud */}
              <div className="card w-1/2">
                <div className='card-body'>
                  <h6 className='text-white text-lg'>Negative Trending Topics</h6>
                </div>
                <div className="border-t border-gray-600 dark:border-gray-300 my-4"></div>
                <div className='card-body flex-grow flex items-center justify-center'>
                  {wordcloudLoading ? (
                    <p>Loading negative wordcloud...</p>
                  ) : wordcloudError ? (
                    <p>Error loading negative wordcloud: {wordcloudError}</p>
                  ) : wordcloudImageUrl.wordcloud_negative ? (
                    <img src={wordcloudImageUrl.wordcloud_negative} alt="Negative Wordcloud" className="max-w-full max-h-full object-contain" />
                  ) : (
                    <p className="text-center text-slate-500">No negative wordcloud</p>
                  )}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ChannelDashboard;
