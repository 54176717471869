import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getUserSubscription } from "./thunk";

interface ChannelSubscription {
  channel_id: string;
  channel_name: string | null;
  scrap_status: string;
  last_scrapped_at: string | null;
  subscription_id: string;
  date_subscribed_end: string;
  expired: boolean;
  unsubscribed: boolean;
}

interface ServerSubscription {
  server_id: string;
  server_name: string | null;
  scrap_status: string;
  last_scrapped_at: string | null;
  date_subscribed_end: string;
  expired: boolean;
  subscription_id: string;
  is_deleted: boolean;
  unsubscribed: boolean;
  scrap_channel_id: ChannelSubscription[];
}

interface UserSubscriptionData {
  user_id: string;
  data: ServerSubscription[];
}

interface UserSubscriptionState {
  subscriptionData: UserSubscriptionData | null;
  loading: boolean;
  error: string | null;
}

const initialState: UserSubscriptionState = {
  subscriptionData: null,
  loading: false,
  error: null,
};

const userSubscriptionSlice = createSlice({
  name: "userSubscription",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserSubscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserSubscription.fulfilled, (state, action: PayloadAction<UserSubscriptionData>) => {
        state.loading = false;
        state.subscriptionData = action.payload;
        state.error = null;
      })
      .addCase(getUserSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
        state.subscriptionData = null;
      });
  },
});

export default userSubscriptionSlice.reducer;