import { useSelector } from "react-redux";
import { RootState } from "../slices";
import { PieChart, BarChart } from "lucide-react";

interface Server {
  server_id: string;
  server_name: string;
  scrap_channel_id: { channel_id: string | number; channel_name: string }[];
}

const useMenuData = () => {
  const { subscriptionData } = useSelector((state: RootState) => state.UserSubscription);

  const generateServerLists = () => {
    if (!subscriptionData || !subscriptionData.data) {
      return [];
    }

    return subscriptionData.data
      .filter((server: any) => server.scrap_status !== "pending")
      .map((server: any) => ({
        id: `any-${server.server_id}`,
        label: server.server_name,
        icon: <BarChart />,
        link: `/server/${server.server_id}`,
      }));
  };

  const generateChannelLists = () => {
    if (!subscriptionData || !subscriptionData.data) {
      return [];
    }

    return subscriptionData.data
      .filter((server: any) => server.scrap_status !== "pending")
      .map((server: any) => ({
        id: `server-${server.server_id}`,
        label: server.server_name,
        icon: <PieChart />,
        link: `/server/${server.server_id}`,
        subItems: server.scrap_channel_id.map((channel: any) => ({
          id: `channel-${channel.channel_id}`,
          label: channel.channel_name,
          link: `/channel/${channel.channel_id}`,
          icon: <PieChart />,
          parentId: `server-${server.server_id}`
        }))
      }));
  };

  return [
    {
      label: 'menu',
      isTitle: true,
    },
    {
      id: "dashboard",
      label: 'Dashboards',
      link: "/#",
      icon: <PieChart />,
    },
    {
      label: 'server list',
      isTitle: 'true',
    },
    ...generateServerLists(),
    {
      label: "channels list",
      isTitle: true,
    },
    ...generateChannelLists()
  ];
};

export { useMenuData };